import React, { useState } from "react";
import Title from "../utils/Title";
import { useTranslation } from "react-i18next";
import { request } from "../utils/axios.js";
import LoadingBtn from "../utils/loadingBtn/LoadingBtn";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
const Contact = ({ branches }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    setName(inputValue);
    if (/\d/.test(inputValue)) {
      setNameError(t("nameError"));
    } else {
      setNameError("");
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePhoneChange = (e) => {
    const input = e.target.value;
    setPhone(input);
    if (/^\d*$/.test(input)) {
      setPhone(input);
      setPhoneError("");
    } else {
      setPhoneError(t("phoneError"));
    }
  };
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const sendData = (data) => {
    return request({ url: "/contact-us", method: "post", data });
  };
  const { isLoading, mutate } = useMutation(sendData, {
    onSuccess: (data) => {
      if (data?.data?.status) {
        toast.success(data?.data?.message);
        // clear inputs
        setEmail("");
        setMessage("");
        setName("");
        setPhone("");
      } else {
        toast.error(data?.data?.message);
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (
      name.trim() === "" ||
      phone.trim() === "" ||
      email.trim() === "" ||
      message.trim() === ""
    ) {
      toast.error(t("req"));
    } else {
      const userData = {
        name,
        phone,
        email,
        message,
      };
      mutate(userData);
    }
  };
  return (
    <div className="main-div py-5 flex items-center my-16">
      <div className="container mx-auto px-6 md:px-16  overflow-x-hidden">
        <div className="flex justify-center mb-8">
          <Title title={t("contact")} />
        </div>
        <div
          data-aos="fade-right"
          data-aos-delay="300"
          className="grid grid-cols-1 md:grid-cols-2  gap-8 "
        >
          <form className="sans" onSubmit={handleClick}>
            <div className="mb-5">
              <input
                className="inp"
                type="text"
                placeholder={t("name")}
                value={name}
                onChange={handleNameChange}
                required
              />
              {nameError ? (
                <p className="text-main text-sm my-1">{nameError}</p>
              ) : null}
            </div>
            <div className="mb-5">
              <input
                className="inp"
                type="email"
                placeholder={t("email")}
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div className="mb-5">
              <input
                className="inp"
                type="text"
                placeholder={t("phone")}
                value={phone}
                onChange={handlePhoneChange}
                required
              />
              {phoneError ? (
                <p className="text-main text-sm my-1">{phoneError}</p>
              ) : null}
            </div>

            <div className="mb-5">
              <textarea
                className="inp area"
                placeholder={t("message")}
                value={message}
                onChange={handleMessageChange}
                required
              ></textarea>
            </div>
            {isLoading ? (
              <div className=" w-56 flex items-center mx-auto">
                <LoadingBtn />
              </div>
            ) : (
              <button
                type="submit"
                className="py-3 px-8 rounded-lg text-white w-56 mx-auto bg-main flex justify-center"
              >
                {t("send")}
              </button>
            )}
          </form>
          <div>
            <div className="mb-5 overflow-hidden ">
              {branches.map((branch, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <p className="font-bold text-lg text-main mb-1">
                    {branch.title}
                  </p>

                  <div
                    dangerouslySetInnerHTML={{ __html: branch.location }}
                    style={{ height: "250px" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
