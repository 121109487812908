import React from "react";
import Title from "../utils/Title";
import { useTranslation } from "react-i18next";

const Partners = ({ data, showTitle }) => {
  const { t } = useTranslation();

  return (
    <>
      {showTitle ? (
        <div className="flex justify-center my-6">
          <Title title={t("clients")} />
        </div>
      ) : null}
      <div className="partner-div  py-3 flex items-center">
        <div className="container mx-auto px-6 md:px-16 ">
          <div className="scroll-content">
            {data.map((item, index) => (
              <img
                alt="partner-img"
                src={item}
                key={index}
                className="w-[120px] max-h-14 object-cover item"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
/**
 *   <div
      data-aos="fade-up"
      data-aos-delay="300"
      className="partner-div mt-6 p-6 flex items-center"
    >
      <div className="container mx-auto px-6 md:px-16 ">
        {showTitle ? (
          <div className="flex justify-center mb-6">
            <Title title={t("clients")} />
          </div>
        ) : null}

        <Slider {...settings}>
          {data.map((item, index) => (
            <img
              alt="partner-img"
              src={item}
              key={index}
              className="max-w-[120px] max-h-14 object-cover"
            />
          ))}
        </Slider>
      </div>
    </div>
 */
