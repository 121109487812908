import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaCartArrowDown, FaEye, FaCheck } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { addToCart } from "../../Redux/cart";
const BlogCard = ({ data, showDesc, path, showPrice, isAddToCart }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const handleAddToCart = (item) => dispatch(addToCart(item));
  return (
    <div
      className=" rounded-md bg-[#222]"
      data-aos="zoom-in"
      data-aos-delay={data.delay}
    >
      <img
        alt={data.title}
        src={data.image}
        className="w-full h-[150px] md:h-[300px] object-cover"
      />
      <div className="p-5">
        <p className="text-main font-bold text-xl md:text-2xl mb-4 text-center">
          {data.title}
        </p>
        {showDesc ? (
          <div
            className="text-center  text-white mb-4"
            dangerouslySetInnerHTML={{
              __html: data.description.substr(0, 100),
            }}
          />
        ) : null}
        {/**
             * <p>
            {i18n.language === "ar"
              ? data.arDesc.substr(0, 100) + "..."
              : data.enDesc.substr(0, 100) + "..."}
          </p>
             */}
        {showPrice ? (
          <div className="sans my-4 text-slate-400 font-bold">
            {data.price} {i18n.language === "ar" ? "ر.س" : "SAR"}
          </div>
        ) : null}
        {isAddToCart ? (
          <div className="flex items-center gap-2">
            <button className=" w-10 h-10 rounded-[50%] bg-main text-white flex items-center justify-center">
              <Link to={`${path}/${data.id}`}>
                <FaEye size={20} />
              </Link>
            </button>
            {data.isAdded ? (
              <div className=" w-10 h-10 rounded-[50%] bg-main text-white flex items-center justify-center bg-opacity-55">
                <FaCheck size={20} />
              </div>
            ) : (
              <button
                onClick={() => handleAddToCart(data)}
                className=" w-10 h-10 rounded-[50%] bg-main text-white flex items-center justify-center"
              >
                {data.isAdded ? (
                  <FaCheck size={20} />
                ) : (
                  <FaCartArrowDown size={20} />
                )}
              </button>
            )}
          </div>
        ) : (
          <div className="flex justify-center">
            <button className="p-2 md:py-3  md:px-8 rounded-lg text-white  mx-auto bg-main text-sm">
              <Link to={`${path}/${data.id}`}>{t("more")}</Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogCard;
