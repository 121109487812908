import React from "react";
const Hero = ({ data }) => {
  return (
    <div className=" w-screen h-[300px] md:h-[450px]">
      <img alt="banner-img" src={data} className="w-full h-full object-cover" />
    </div>
  );
};

export default Hero;
/**
 *  <video className="w-full h-full object-cover" autoPlay muted loop>
        <source src={heroVideo} type="video/mp4" />
      </video>
 */
