import React from "react";
import { useTranslation } from "react-i18next";
import BlogCard from "../components/utils/BlogCard";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
const Pricings = () => {
  const { t } = useTranslation();
  const fetchData = () => {
    return request({
      url: "/services",
    });
  };
  const { isLoading, data } = useQuery("services", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="main-div py-5 flex items-center my-12">
          {data?.data?.data?.services?.length ? (
            <div className="container mx-auto px-6 md:px-16">
              <h2
                className="font-bold text-main text-center text-xl md:text-2xl lg:text-3xl mb-8"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                {t("services")}
              </h2>
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-8">
                {data?.data?.data?.services.map((item, index) => (
                  <BlogCard
                    key={index}
                    data={item}
                    showDesc={true}
                    path="/services"
                    showPrice={false}
                    isAddToCart={false}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Pricings;
