import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// react hot toast
import { Toaster } from "react-hot-toast";
// aos
import "aos/dist/aos.css";
// i18next
import "./i18n";
// react query
import { QueryClientProvider, QueryClient } from "react-query";
// react router dom
import { BrowserRouter as Router } from "react-router-dom";
// redux
import { Provider } from "react-redux";
import store from "./Redux/store";
// handle scroll to top
import ScrollToTopAfterChangePage from "./components/utils/ScrollToTopAfterChangePage";
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Toaster position="top-center" reverseOrder={false} />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTopAfterChangePage />
          <App />
        </Router>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);
