import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
const Statistics = ({ data }) => {
  const { i18n } = useTranslation();
  const [count, setCount] = useState(data.map(() => 0));
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once when section becomes visible
  });
  useEffect(() => {
    const incrementAutomatically = () => {
      if (inView) {
        setCount((prevCounters) =>
          prevCounters.map((count, index) =>
            count < data[index].number ? count + 1 : count
          )
        );
      }
    };

    const interval = setInterval(incrementAutomatically, 50);
    return () => {
      clearInterval(interval);
    };
  }, [inView, data]);
  return (
    <div
      data-aos="fade-up"
      className="w-screen flex items-center py-6 bg-opacity mb-6 overflow-x-hidden "
      ref={ref}
      style={{ overflowX: "hidden" }}
    >
      <div
        className="container mx-auto px-6 md:px-16 overflow-x-hidden"
        style={{ overflowX: "hidden" }}
      >
        <div className="grid grid-cols-2 md:grid-cols-4 mb-3 md:mb-0">
          {data.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-1 text-white"
            >
              <p className="text-xl md:text-2xl lg:text-3xl font-extrabold gotham">
                +{count[index]}
              </p>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
