import React from "react";
import FeatuersCard from "../utils/FeatuersCard";

const Featuers = ({ data }) => {
  return (
    <div className="main-div py-4 min-h-[300px]  flex items-center  my-16  overflow-x-hidden">
      <div className="container mx-auto px-6 md:px-16 overflow-x-hidden">
        <div
          data-aos="fade-left"
          data-aos-delay="400"
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4"
        >
          {data.map((item, index) => (
            <FeatuersCard data={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Featuers;
