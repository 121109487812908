import React from "react";
import Title from "../utils/Title";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Portfolio = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="container mx-auto px-6 md:px-16 my-16">
      <div className="flex justify-center mb-8">
        <Title title={t("portfolio")} />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {data.slice(0, 4).map((item, index) => (
          <div className="relative" key={index}>
            <img
              alt={item.arTitle}
              src={item.image}
              className="w-full h-full object-cover"
            />
            <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 p-4">
              <div className=" absolute right-4 bottom-6">
                <p className="text-sm text-white mb-2">{item.description}</p>
                <p className="text-white text-lg md:text-xl font-extrabold">
                  {item.title}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {data.length > 4 ? (
        <div className="mt-5 flex justify-center">
          <button className="py-3 px-8 rounded-lg text-white w-56 mx-auto bg-main">
            <Link to="/portfolio">{t("more")}</Link>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Portfolio;
