import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "../components/utils/Title";
import BlogCard from "../components/utils/BlogCard";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
import Meta from "../components/utils/Meta";
const Pricing = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const fetchData = (v) => {
    return request({
      url: `/services/${v}`,
    });
  };
  const { isLoading, data } = useQuery(["services-details", id], () =>
    fetchData(id)
  );

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Meta
            title={data?.data?.data?.service?.meta_title}
            desc={data?.data?.data?.service?.meta_description}
          />
          <div className="main-div py-4 flex items-center my-12">
            <div className="container mx-auto px-6 md:px-16  overflow-hidden">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16  overflow-hidden">
                <img
                  data-aos="fade-down"
                  data-aos-delay="400"
                  alt={data?.data?.data?.service.title}
                  src={data?.data?.data?.service.image}
                  className="w-full max-h-[350px] object-cover"
                />

                <div className=" flex flex-col items-center overflow-hidden">
                  <div
                    data-aos="fade-down"
                    data-aos-delay="300"
                    className="mb-8 text-main font-bold text-xl md:text-2xl lg:text-3xl"
                  >
                    {data?.data?.data?.service?.title}
                  </div>
                  <div
                    className="text-slate-400 mb-8"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    dangerouslySetInnerHTML={{
                      __html: data?.data?.data?.service?.description,
                    }}
                  />
                </div>
              </div>
              {data?.data?.data?.realated_services?.length ? (
                <>
                  <div className="my-12 flex justify-center">
                    <Title title={t("relatedPricings")} />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {data?.data?.data?.realated_services
                      .slice(0, 4)
                      .map((item, index) => (
                        <BlogCard
                          data={item}
                          key={index}
                          showDesc={true}
                          path="/services"
                          showPrice={false}
                        />
                      ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Pricing;
