import React from "react";
import { useTranslation } from "react-i18next";
const FeatuersCard = ({ data }) => {
  const { i18n } = useTranslation();
  return (
    <div className="bg-white rounded-lg shadow-md p-3 flex flex-col items-center justify-center gap-3  h-40 cursor-pointer duration-300 relative card">
      <div className="icon">
        <img
          alt="featuers-img"
          src={data.image}
          className=" h-12 object-cover "
        />
      </div>
      <p className="text-xl font-bold text">{data.title}</p>
    </div>
  );
};

export default FeatuersCard;
