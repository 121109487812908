import React from "react";
import About from "../components/home/About";
import Featuers from "../components/home/Featuers";
import Partners from "../components/home/Partners";
import Statistics from "../components/home/Statistics";
import Portfolio from "../components/home/Portfolio";
import Contact from "../components/home/Contact";
import Team from "../components/home/Team";
import Reviews from "../components/home/Reviews";
import Meta from "../components/utils/Meta";
import Hero from "../components/home/Hero";
const Home = ({
  about,
  selgon,
  featuers,
  partners,
  stats,
  seo,
  portfolio,
  branches,
  reviews,
  hero,
}) => {
  return (
    <div>
      <Meta title={seo?.title} desc={seo?.description} />
      <Hero data={hero} />
      <About data={about} selgon={selgon} />
      {featuers?.length ? <Featuers data={featuers} /> : null}
      {portfolio?.length ? <Portfolio data={portfolio} /> : null}
      {partners?.length ? <Partners data={partners} showTitle={true} /> : null}
      {stats?.length ? <Statistics data={stats} /> : null}
      <Contact branches={branches} />
      {reviews.length ? <Reviews data={reviews} /> : null}
      {/* <Services data={services} /> */}

      {/* <Team data={team} /> */}
    </div>
  );
};

export default Home;
