import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import Title from "../utils/Title";
const Team = ({ data }) => {
  const { t, i18n } = useTranslation();
  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    autoplay: true, // Enable autoplay
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    verical: false,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className=" overflow- my-16 w-screen pt-4 pb-2 bg-[#222]">
      <div>
        <div className="flex justify-center  mb-8 text-center mx-auto">
          <Title title={t("team")} />
        </div>

        <Slider {...settings}>
          {data.map((item, index) => (
            <div key={index} className="md:px-3">
              <div className="h-96 relative">
                <img
                  alt={item.name}
                  src={item.image}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 px-3 text-white">
                  <div
                    className={`flex flex-col gap-1 items-center absolute bottom-10 ${
                      i18n.language === "ar" ? "right-3" : "left-3"
                    }`}
                  >
                    <p className="font-bold text-lg md:text-xl">{item.name}</p>
                    <p>{item.position}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Team;
