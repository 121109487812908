import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
const initialState = {
  cartItems: JSON.parse(window.localStorage.getItem("cart")) || [],
  isCartOpen: false,
};
const cartSlice = createSlice({
  initialState,
  name: "cartSlice",
  reducers: {
    // open cart
    openCart: (state) => {
      state.isCartOpen = true;
    },
    // close cart
    closeCart: (state) => {
      state.isCartOpen = false;
    },
    // add to cart
    addToCart: (state, action) => {
      const index = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index >= 0) {
        state.cartItems[index].isAdded = true;
        toast.error(`${action.payload.title}   موجود بالفعل`);
      } else {
        const temp = { ...action.payload, isAdded: false };
        state.cartItems.push(temp);
        toast.success(`${action.payload.title} تم اضافتة للعربة`);
      }
      window.localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },
    // remove from cart :
    removeFromCart: (state, action) => {
      const newItems = state.cartItems.filter(
        (item) => item.id !== action.payload.id
      );
      state.cartItems = newItems;
      toast.success(`${action.payload.arTitle} تم حذفة من العربة`);
      window.localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    // clear
    clearCart: (state) => {
      state.cartItems = [];
      window.localStorage.setItem("cart", state.cartItems);
    },
  },
});
export const {
  openCart,
  closeCart,
  addToCart,
  removeFromCart,

  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
