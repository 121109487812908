import React, { useEffect } from "react";
import * as animationData from "../assets/lotties/error.json";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const ErrorPage = ({ setShowNav }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    setShowNav(false);
  }, []);
  return (
    <div className="overflow-x-hidden">
      <div className="w-full h-full flex justify-center items-center">
        <div className="flex flex-col items-center gap-8">
          <div>
            <Lottie
              isClickToPauseDisabled
              options={defaultOptions}
              height={500}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
