import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SocialMedia from "../utils/SocialMedia";
const Footer = ({ socialMedia, links, logo, selgon, contact }) => {
  const isAnySocialMediaFilled = Object.values(socialMedia).some(
    (value) => value !== ""
  );
  const isAnyContact = Object.values(contact).some((value) => value !== "");
  const { t, i18n } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <>
      <div className="h-[2px] w-screen bg-btn"></div>
      <div className="main-div flex items-center">
        <div className="w-screen  pt-8 pb-3   flex items-center bg-black overflow-hidden">
          <div className="container  mx-auto px-6 md:px-16">
            <div
              className={`grid grid-cols-1 md:grid-cols-2 ${
                isAnySocialMediaFilled ? "lg:grid-cols-4" : "lg:grid-cols-3"
              } lg:grid-cols-4    mb-4`}
            >
              <div className="flex flex-col justify-center md:justify-start items-center">
                <Link to="/">
                  <img alt="logo" src={logo} className=" w-40 object-cover" />
                </Link>

                <p className="text-gray-400 mb-2">{selgon}</p>
              </div>
              <div className="flex flex-col justify-center md:justify-start items-center">
                <p className="text-main font-bold text-lg md:text-xl mb-4">
                  {t("important")}
                </p>
                <ul>
                  {links.map((link, i) => (
                    <li key={i} className="mb-2">
                      <Link
                        to={link.path}
                        className="text-white  duration-300 hover:text-gray-400"
                      >
                        {i18n.language === "ar" ? link.arTitle : link.enTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {isAnyContact ? (
                <div className="flex flex-col justify-center md:justify-start items-center text-center">
                  <p className="text-main font-bold text-lg md:text-xl mb-4">
                    {t("contact")}
                  </p>
                  <ul>
                    <a
                      href={`https://tel:+2${contact.phone}`}
                      target="_blank"
                      rel=" noreferrer"
                      className="text-white gotham  duration-300 hover:text-gray-400 block mb-2"
                    >
                      {contact.phone}
                    </a>
                    <a
                      href={`https://mailto:${contact.email}`}
                      target="_blank"
                      rel=" noreferrer"
                      className="text-white gotham  duration-300 hover:text-gray-400 block mb-2  lowercase"
                    >
                      {contact.email}
                    </a>
                    <p className="text-white  duration-300 hover:text-gray-400 block mb-2 cursor-pointer  ">
                      {contact.address}
                    </p>
                  </ul>
                </div>
              ) : null}
              {isAnySocialMediaFilled ? (
                <div className="flex flex-col justify-center md:justify-start items-center">
                  <p className="text-main font-bold text-lg md:text-xl mb-4">
                    {t("social")}
                  </p>
                  <SocialMedia data={socialMedia} />
                </div>
              ) : null}
            </div>
            <div className="sans flex items-center justify-center  md:justify-end text-gray-400 ">
              <div className="flex items-center gap-1">
                <p>{t("copyRight")}</p>
                <p>&copy; {year}</p>
                <a
                  className="font-bold text-main"
                  href={socialMedia.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  next level
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
