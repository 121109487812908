export const navLinks = [
  {
    arTitle: "الرئيسية",
    enTitle: "home",
    path: "/",
  },
  {
    arTitle: "من نحن",
    enTitle: "about",
    path: "/about",
  },
  {
    arTitle: "أعمالنا",
    enTitle: "portfolio",
    path: "/portfolio",
  },
  {
    arTitle: "الخدمات",
    enTitle: "services",
    path: "/services",
  },
  {
    arTitle: "المدونة",
    enTitle: "blogs",
    path: "/blogs",
  },
  {
    arTitle: "الوظائف",
    enTitle: "jobs",
    path: "/jobs",
  },
];

// export const filter = [
//   {
//     id: 1,
//     arTitle: "جميع الأعمال",
//     enTitle: "all",
//   },
//   {
//     id: 2,
//     arTitle: "تطبيقات ويب",
//     enTitle: "Website",
//   },
//   {
//     id: 3,
//     arTitle: "تطبيقات هاتف",
//     enTitle: "mobile application",
//   },
//   {
//     id: 4,
//     arTitle: "متاجر إلكترونية",
//     enTitle: "e-commerce",
//   },
//   {
//     id: 5,
//     arTitle: "تصميمات سوشيال ميديا",
//     enTitle: "social media designs",
//   },
//   {
//     id: 6,
//     arTitle: "تصميم فيديوهات",
//     enTitle: "video designs",
//   },
//   {
//     id: 7,
//     arTitle: "تحسين محركات البحث",
//     enTitle: "SEO Enhancment",
//   },
//   {
//     id: 8,
//     arTitle: "تسويق الكتروني",
//     enTitle: "digital markteing",
//   },
//   {
//     id: 9,
//     arTitle: "كتابة محتوي",
//     enTitle: "content creation",
//   },
//   {
//     id: 10,
//     arTitle: "فويس اوفر",
//     enTitle: "voice over",
//   },
// ];
// export const works = [
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 1,
//   },
//   {
//     img: port2,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 2,
//   },
//   {
//     img: port3,
//     arTitle: "مرسول",
//     enTitle: "marsol",
//     arDesc: "تطبيقات هاتف",
//     enDesc: "mobile application",
//     delay: "600",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 3,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 4,
//   },
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 5,
//   },
//   {
//     img: port2,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 6,
//   },
//   {
//     img: port3,
//     arTitle: "مرسول",
//     enTitle: "marsol",
//     arDesc: "تطبيقات هاتف",
//     enDesc: "mobile application",
//     delay: "600",
//     categoryId: 2,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 7,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 3,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 8,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 3,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 9,
//   },
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 3,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 10,
//   },
//   {
//     img: port2,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 3,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 11,
//   },
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 12,
//   },
//   {
//     img: port2,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 13,
//   },
//   {
//     img: port3,
//     arTitle: "مرسول",
//     enTitle: "marsol",
//     arDesc: "تطبيقات هاتف",
//     enDesc: "mobile application",
//     delay: "600",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 14,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 15,
//   },
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 16,
//   },
//   {
//     img: port2,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 17,
//   },
//   {
//     img: port3,
//     arTitle: "مرسول",
//     enTitle: "marsol",
//     arDesc: "تطبيقات هاتف",
//     enDesc: "mobile application",
//     delay: "600",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 18,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 19,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 20,
//   },
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 21,
//   },
//   {
//     img: port2,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 22,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 23,
//   },
//   {
//     img: port4,
//     arTitle: "د/ حازم سلطان",
//     enTitle: "Dr/ hazem sultan",
//     arDesc: "تصميم سوشيال ميديا",
//     enDesc: "socail media designs",
//     delay: "700",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 24,
//   },
//   {
//     img: port1,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 25,
//   },
//   {
//     img: port5,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 4,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 26,
//   },
//   {
//     img: port8,
//     arTitle: "مول الطنطاوي",
//     enTitle: "El-Tantawy Mall",
//     arDesc: "تصميم متاجر إلكترونية",
//     enDesc: "Design of electronic stores",
//     delay: "400",
//     categoryId: 5,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 28,
//   },
//   {
//     img: port7,
//     arTitle: "ترافيل",
//     enTitle: "travel",
//     arDesc: "تصميم مواقع",
//     enDesc: "Web Design",
//     delay: "500",
//     categoryId: 5,
//     slider: [port1, port2, port3, port4, port5, port6, port7, port8],
//     services: [
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//       {
//         arTitle: "عنوان الخدمة",
//         enTitle: "service title",
//         icon: <MdAppSettingsAlt size={20} />,
//       },
//     ],
//     arContent:
//       'باقة "أبشر" المُقدمة من شركة كفو لخدمات التسويق للمتاجر الإلكترونية تمثل خطوة متقدمة نحو تحسين أداء وجاذبية المتاجر عبر الإنترنت، تتيح هذه الباقة للأعمال التجارية الاستفادة من مجموعة شاملة من الخدمات التسويقية، بدءًا من تحسين رؤية المتجر في محركات البحث، وصولاً إلى تحليلات متقدمة لفهم سلوك المستهلكين وتوجيه الحملات التسويقية بشكل فعال. تُسهم هذه الخدمات في زيادة حركة المرور إلى المتجر الإلكتروني، تحسين تجربة المستخدم، وزيادة معدلات التحويل، بفضل "أبشر" يمكن للمتاجر الإلكترونية تحقيق نمو مستدام وتعزيز تواجدها الرقمي، وهو جوهري في عصر التسوق عبر الإنترنت.',
//     enContent:
//       "The “Absher” package provided by Kafu Company for marketing services for electronic stores represents an advanced step towards improving the performance and attractiveness of online stores. This package allows businesses to benefit from a comprehensive set of marketing services, starting from improving the visibility of the store in search engines, all the way to advanced analytics to understand Consumer behavior and effective marketing campaigns. These services contribute to increasing traffic to the online store, improving the user experience, and increasing conversion rates. Thanks to Absher, online stores can achieve sustainable growth and enhance their digital presence, which is essential in the era of online shopping.",
//     id: 29,
//   },
// ];
// export const accountDetailsFilter = [
//   {
//     id: 1,
//     arTitle: "الإعدادات العامة",
//     enTitle: "account settings",
//   },
//   {
//     id: 2,
//     arTitle: "تغيير كلمة المرور",
//     enTitle: "change password",
//   },
// ];
