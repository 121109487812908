import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Routes, Route, useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// data
import { navLinks } from "./fakers/data";
// shared layout
import Nav from "./components/layout/Nav";
import Footer from "./components/layout/Footer";
import FixedBtns from "./components/utils/FixedBtns";

// import CartSidebar from "./components/cart/CartSidebar";
// pages
import Home from "./pages/Home";
import Hero from "./components/home/Hero";
import Jobs from "./pages/Jobs";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Pricings from "./pages/Pricings";
import Pricing from "./pages/Pricing";
import Portfolio from "./pages/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails";
// import Regester from "./pages/Regester";
// import Login from "./pages/Login";
// import OTP from "./pages/OTP";
// import Forget from "./pages/Forget";
// import MyAccount from "./pages/MyAccount";
// import PasswordOtp from "./pages/PasswordOtp";
// import NewPassword from "./pages/NewPassword";

// hooks
// import { useSelector } from "react-redux";
// handle fetch api
import { useQuery } from "react-query";
import { request } from "./components/utils/axios";
import Spinner from "./components/utils/Spinner";
import Meta from "./components/utils/Meta";
// import OrderSuccess from "./pages/OrderSuccess";
// import OrderFaild from "./pages/OrderFaild";
// import Cart from "./pages/Cart";
import ErrorPage from "./pages/ErrorPage";
const App = () => {
  const [showNav, setShowNav] = useState(true);
  const navigate = useNavigate();
  // const { isCartOpen, cartItems } = useSelector((state) => state.cartSlice);
  // const totalPrice = cartItems.reduce((acc, product) => {
  //   acc += product.price;
  //   return acc;
  // }, 0);
  // const { i18n } = useTranslation();
  // handle animation
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      mirror: false,
    });
  }, []);
  // handle language
  // useEffect(() => {
  //   localStorage.setItem("lang", JSON.stringify(i18n.language));
  // }, [i18n.language]);
  // useEffect(() => {
  //   document.documentElement.setAttribute("lang", i18n.language);
  //   document.body.setAttribute("data-language", i18n.language);

  //   if (i18n.language === "ar") {
  //     document.getElementsByTagName("body")[0].style.direction = "rtl";
  //   } else {
  //     document.getElementsByTagName("body")[0].style.direction = "ltr";
  //   }
  // }, [i18n.language]);
  // fetch data
  const fetchData = () => {
    return request({
      url: "/home",
    });
  };
  const { isLoading, data } = useQuery("home-page", fetchData);
  if (isLoading) {
    return <Spinner />;
  }
  if (!data) {
    navigate("/error");
    return null;
  }
  return (
    <>
      <Meta
        fav={data?.data?.data?.site?.favicon}
        title={data?.data?.data?.site?.title}
        desc={data?.data?.data?.site?.description}
      />
      {showNav ? (
        <>
          <Nav
            logo={data?.data?.data?.site?.logo}
            socialMedia={data?.data?.data?.social}
            links={navLinks}
          />
          {/* <Hero data={data?.data?.data.banner[0]} /> */}
        </>
      ) : null}

      <FixedBtns whatsapp={data?.data?.data?.contact?.whatsapp} />

      <Routes>
        <Route
          path="/"
          element={
            <Home
              hero={data?.data?.data.banner[0]}
              selgon={data?.data?.data?.site?.selgon}
              about={data?.data?.data?.aboutUS}
              featuers={data?.data?.data?.features}
              partners={data?.data?.data?.clients}
              stats={data?.data?.data?.statistics}
              services={data?.data?.data?.services}
              portfolio={data?.data?.data?.work}
              branches={data?.data?.data?.branches}
              team={data?.data?.data?.team}
              reviews={data?.data?.data?.reviews}
              seo={data?.data?.data?.site}
            />
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/services" element={<Pricings />} />
        <Route path="/services/:id" element={<Pricing />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<Blog />} />
        <Route
          path="/portfolio"
          element={<Portfolio partners={data?.data?.data?.clients} />}
        />
        <Route path="/portfolio/:id" element={<PortfolioDetails />} />
        {/**auth*/}
        {/**
             *  <Route path="/regester" element={<Regester />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget" element={<Forget />} />
        <Route path="/password-otp" element={<PasswordOtp />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route
          path="/my-account"
          element={<MyAccount accountDetailsFilter={accountDetailsFilter} />}
        />
             */}
        {/*checkout*/}
        {/**
             *    <Route
          path="/cart"
          element={<Cart cartItems={cartItems} totalPrice={totalPrice} />}
        />
        <Route path="/order-success" element={<OrderSuccess />} />
        <Route path="/order-faild" element={<OrderFaild />} />
             */}
        <Route path="*" element={<ErrorPage setShowNav={setShowNav} />} />
      </Routes>
      {showNav ? (
        <Footer
          selgon={data?.data?.data?.site?.selgon}
          logo={data?.data?.data?.site?.logo}
          socialMedia={data?.data?.data?.social}
          links={navLinks}
          contact={data?.data?.data?.contact}
        />
      ) : null}
    </>
  );
};

export default App;
