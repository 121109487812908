import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "../components/utils/Title";
import BlogCard from "../components/utils/BlogCard";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
import Meta from "../components/utils/Meta";
const Blog = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const fetchData = (v) => {
    return request({
      url: `/blogs/${v}`,
    });
  };
  const { isLoading, data } = useQuery(["blogs-details", id], () =>
    fetchData(id)
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Meta
            title={data?.data?.data?.blog?.meta_title}
            desc={data?.data?.data?.blog?.meta_description}
          />
          <div className="container mx-auto px-6 md:px-16 my-12">
            <h2
              className="text-center text-xl md:text-2xl lg:text-3xl text-main"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              {data?.data?.data?.blog?.title}
            </h2>
            <img
              className="w-full max-h-[400px] object-cover my-8"
              alt={data?.data?.data?.blog?.title}
              src={data?.data?.data?.blog?.addtionalImage}
            />
            <div className="w-full bg-[#333] p-4">
              <div
                className=" text-slate-300"
                dangerouslySetInnerHTML={{
                  __html: data?.data?.data?.blog?.description,
                }}
              />
            </div>

            {/** <p className=" text-slate-300">
            {i18n.language === "ar" ? data.arDesc : data.enDesc}
          </p> */}

            {data?.data?.data?.related_blogs?.length ? (
              <>
                <div className="my-12 flex justify-center">
                  <Title title={t("relatedBlogs")} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                  {data?.data?.data?.related_blogs
                    .slice(0, 4)
                    .map((item, index) => (
                      <BlogCard
                        data={item}
                        key={index}
                        showDesc={true}
                        path="/blogs"
                        showPrice={false}
                      />
                    ))}
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default Blog;
