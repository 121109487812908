import React from "react";

const Spinner = () => {
  return (
    <div className="fixed w-screen h-screen flex items-center justify-center z-50 bg-white bg-opacity-50">
      <div className="dots"></div>
    </div>
  );
};

export default Spinner;
