import React from "react";

const Title = ({ title }) => {
  return (
    <div
      data-aos="fade-down"
      data-aos-delay="400"
      className="text-xl title md:text-2xl lg:text-3xl xl:text-4xl font-extrabold text-main"
    >
      {title}
    </div>
  );
};

export default Title;
