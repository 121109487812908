import React from "react";
import Title from "../utils/Title";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
const About = ({ data, selgon }) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-x-hidden container mx-auto px-6 md:px-16 my-12">
      <div className="flex flex-col md:flex-row gap-5  justify-center items-center">
        <div data-aos="fade-left" className="md:w-1/2">
          {data?.title ? <Title title={data.title} /> : null}
          {selgon ? (
            <p className="my-3 text-white font-medium text-lg">{selgon}</p>
          ) : null}
          {data?.description ? (
            <p className="my-3 text-white font-medium ">{data.description}</p>
          ) : null}

          {data.phone ? (
            <div className="flex items-center gap-5">
              <button className=" w-44  capitalize  py-3 px-5 flex items-center justify-center  bg-main  rounded-full">
                <a
                  href={`tel:02${data.phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("ask")}
                </a>
              </button>
              <FaPhoneAlt size={20} className=" text-gray-400" />
              <div>
                <p className="text-sm text-gray-400 mb-2">{t("happy")}</p>
                <p className="text-xl text-white font-bold mb-2 gotham">
                  {data?.phone}
                </p>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          <img
            alt="about-img"
            src={data?.image}
            className=" max-w-full h-[350px] object-cover text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
