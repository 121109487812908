import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
import toast from "react-hot-toast";
const Jobs = () => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [year, setYear] = useState("");
  const [yearError, setYearError] = useState("");
  const [birthday, setBirthday] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [address, setAddress] = useState("");
  const [education, setEducation] = useState("");
  const [educationError, setEducationError] = useState("");
  const [exp, setExp] = useState("");
  const [company, setCompany] = useState("");
  const [period, setPeriod] = useState("");
  const [periodError, setPeriodError] = useState("");
  const [path, setPath] = useState("");
  const [salary, setSalary] = useState("");
  const [salaryError, setSalaryError] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [portfolioError, setPortfolioError] = useState("");
  const [reason, setReason] = useState("");
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to check if the input contains only letters
    const lettersOnly = /^[A-Za-z]+$/;

    if (inputValue.trim() === "") {
      setNameError("");
    } else if (!lettersOnly.test(inputValue)) {
      setNameError(t("nameError"));
    } else {
      setNameError("");
    }
    setName(inputValue);
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);

    if (value === "") {
      setPhoneError("");
    } else if (!/^\d+$/.test(value)) {
      setPhoneError(t("phoneError"));
    } else {
      setPhoneError("");
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleYearChange = (e) => {
    const value = e.target.value;
    const currentYear = new Date().getFullYear(); // Get the current year

    setYear(value);

    if (value === "") {
      setYearError("");
    } else if (!/^\d+$/.test(value)) {
      setYearError(t("phoneError"));
    } else if (parseInt(value) > currentYear) {
      setYearError(t("yearError"));
    } else {
      setYearError("");
    }
  };
  const handleBirthdayChange = (e) => {
    setBirthday(e.target.value);
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const handleEducationChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to check if the input contains only letters
    const lettersOnly = /^[A-Za-z]+$/;

    if (inputValue.trim() === "") {
      setEducationError("");
    } else if (!lettersOnly.test(inputValue)) {
      setEducationError(t("educationError"));
    } else {
      setEducationError("");
    }
    setEducation(inputValue);
  };
  const handleExpChange = (e) => {
    setExp(e.target.value);
  };
  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };
  const handlePeriodChange = (e) => {
    const value = e.target.value;
    setPeriod(value);

    if (value === "") {
      setPeriodError("");
    } else if (!/^\d+$/.test(value)) {
      setPeriodError(t("phoneError"));
    } else {
      setPeriodError("");
    }
  };
  const handlePathChange = (e) => {
    setPath(e.target.value);
  };
  const handleSalaryChange = (e) => {
    const value = e.target.value;
    setSalary(value);

    if (value === "") {
      setSalaryError("");
    } else if (!/^\d+$/.test(value)) {
      setSalaryError(t("phoneError"));
    } else {
      setSalaryError("");
    }
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };
  const handlePortfolioChange = (e) => {
    const newValue = e.target.value;
    // Regular expression pattern to match portfolio links
    const portfolioLinkPattern =
      /^(http|https):\/\/[a-zA-Z0-9-.]+\.[a-zA-Z]{2,}(\/\S*)?$/;
    setPortfolioError(portfolioLinkPattern.test(newValue));
    setPortfolio(newValue);
  };
  const sendData = (data) => {
    return request({
      url: "/job-request",
      method: "POST",
      data,
    });
  };
  const { isLoading: sending, mutate } = useMutation(sendData, {
    onSuccess: (data) => {
      console.log("this is the data", data);
      if (data?.data?.status) {
        toast.success(data?.data?.message);
        setName("");
        setEmail("");
        setYear("");
        setBirthday("");
        setPhone("");
        setAddress("");
        setEducation("");
        setExp("");
        setCompany("");
        setPeriod("");
        setPath("");
        setSalary("");
        setPortfolio("");
        setReason("");
      } else {
        toast.error(data?.data?.message);
      }
    },
  });
  const handleClick = (e) => {
    e.preventDefault();
    if (
      nameError ||
      yearError ||
      phoneError ||
      educationError ||
      periodError ||
      salaryError
    ) {
      toast.error(t("error"));
    } else {
      const userData = {
        name,
        phone,
        email,
        address,
        qualification: education,
        dob: birthday,
        graduation_year: year,
        last_job: exp,
        company_name: company,
        experience: period,
        salary,
        portfolio_link: portfolio,
        message: reason,
      };
      mutate(userData);
    }
  };
  const fetchData = () => {
    return request({
      url: "/jobs",
    });
  };
  const { isLoading, data } = useQuery("jobs", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="main-div flex items-center py-5 my-16">
          <div className="container mx-auto px-6 md:px-16 ">
            <div className="grid grid-cols-1  justify-center">
              <form
                onSubmit={handleClick}
                className="bg-[#222] p-5 rounded-lg w-full mx-auto sans"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div>
                    <label className="block mb-1 text-main" htmlFor="name">
                      {t("name")}
                    </label>
                    <input
                      id="name"
                      type="text"
                      className="inp"
                      onChange={handleNameChange}
                      value={name}
                    />
                    {nameError ? (
                      <p className="text-sm text-main">{nameError}</p>
                    ) : null}
                  </div>
                  <div>
                    <label className="block mb-1 text-main" htmlFor="email">
                      {t("email")}
                    </label>
                    <input
                      id="email"
                      type="email"
                      className="inp"
                      onChange={handleEmailChange}
                      value={email}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div>
                    <label className="block mb-1 text-main" htmlFor="phone">
                      {t("phone")}
                    </label>
                    <input
                      id="phone"
                      type="text"
                      className="inp"
                      onChange={handlePhoneChange}
                      value={phone}
                    />
                    {phoneError ? (
                      <p className="text-sm text-main">{phoneError}</p>
                    ) : null}
                  </div>
                  <div>
                    <label className="block mb-1 text-main" htmlFor="address">
                      {t("address")}
                    </label>
                    <input
                      id="address"
                      type="text"
                      className="inp"
                      onChange={handleAddressChange}
                      value={address}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div>
                    <label className="block mb-1 text-main" htmlFor="birthday">
                      {t("birthday")}
                    </label>
                    <input
                      id="birthday"
                      type="date"
                      className="inp"
                      onChange={handleBirthdayChange}
                      value={birthday}
                    />
                  </div>
                  <div>
                    <label className="block mb-1 text-main" htmlFor="education">
                      {t("education")}
                    </label>
                    <input
                      id="education"
                      type="text"
                      className="inp"
                      onChange={handleEducationChange}
                      value={education}
                    />
                    {educationError ? (
                      <p className="text-sm text-main">{educationError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div>
                    <label className="block mb-1 text-main" htmlFor="year">
                      {t("year")}
                    </label>
                    <input
                      id="year"
                      type="text"
                      className="inp"
                      onChange={handleYearChange}
                      value={year}
                    />
                    {yearError ? (
                      <p className="text-sm text-main">{yearError}</p>
                    ) : null}
                  </div>
                  <div>
                    <label className="block mb-1 text-main" htmlFor="exp">
                      {t("exp")}
                    </label>
                    <input
                      id="exp"
                      type="text"
                      className="inp"
                      onChange={handleExpChange}
                      value={exp}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div>
                    <label className="block mb-1 text-main" htmlFor="company">
                      {t("company")}
                    </label>
                    <input
                      id="company"
                      type="text"
                      className="inp"
                      onChange={handleCompanyChange}
                      value={company}
                    />
                  </div>
                  <div>
                    <label className="block mb-1 text-main" htmlFor="period">
                      {t("period")}
                    </label>
                    <input
                      id="period"
                      type="text"
                      className="inp"
                      onChange={handlePeriodChange}
                      value={period}
                    />
                    {periodError ? (
                      <p className="text-sm text-main">{periodError}</p>
                    ) : null}
                  </div>
                </div>
                {data?.data?.data?.jobs?.length ? (
                  <div className="mb-5">
                    <label htmlFor="path" className="block mb-1 text-main">
                      {t("path")}
                    </label>
                    {data?.data?.data?.jobs.map((item, index) => (
                      <div key={index} className="flex items-center gap-1 my-4">
                        <input
                          onChange={handlePathChange}
                          type="radio"
                          value={item.id}
                          name="path"
                        />
                        <label className="text-white" htmlFor={path}>
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-5">
                  <div>
                    <label className="block mb-1 text-main" htmlFor="salary">
                      {t("salary")}
                    </label>
                    <input
                      id="salary"
                      type="text"
                      className="inp"
                      onChange={handleSalaryChange}
                      value={salary}
                    />
                    {salaryError ? (
                      <p className="text-sm text-main">{salaryError}</p>
                    ) : null}
                  </div>
                  <div>
                    <label className="block mb-1 text-main" htmlFor="portfolio">
                      {t("portLink")}
                    </label>
                    <input
                      id="portfolio"
                      type="text"
                      className="inp"
                      onChange={handlePortfolioChange}
                      value={portfolio}
                    />
                  </div>
                </div>
                <div className="mb-5">
                  <label className="block mb-1 text-main" htmlFor="reason">
                    {t("reason")}
                  </label>
                  <textarea
                    className="inp area"
                    value={reason}
                    onChange={handleReasonChange}
                  ></textarea>
                </div>
                <div className="flex justify-center mb-5">
                  <button
                    disabled={sending}
                    type="submit"
                    className="flex justify-center items-center py-3 px-6 bg-main w-[200px] rounded-lg"
                  >
                    {t("send")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Jobs;
