import React from "react";
import Title from "../utils/Title";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import useSliderControls from "../../hooks/useSliderControls";
const Reviews = ({ data }) => {
  console.log("data from reviews", data);
  const { t, i18n } = useTranslation();
  const { sliderRef, slickNext, slickPrev } = useSliderControls();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <div className="main-div py-6 flex items-center my-8">
      <div className="container mx-auto px-6 md:px-16 ">
        <div className="flex justify-center mb-5">
          <Title title={t("reveiws")} />
        </div>
        {data.length && (
          <div className="controls">
            <button onClick={slickPrev}>
              {i18n.language === "ar" ? <FaChevronRight /> : <FaChevronLeft />}
            </button>
            <button onClick={slickNext}>
              {i18n.language === "ar" ? <FaChevronLeft /> : <FaChevronRight />}
            </button>
          </div>
        )}
        <Slider ref={sliderRef} {...settings}>
          {data.map((item, index) => (
            <div key={index} className="bg-[#222] rounded-xl p-3 ">
              <img
                alt={item.name}
                src={item.image}
                className=" w-28 h-28 rounded-[50%] object-cover mx-auto"
              />
              <p className="text-white font-bold text-lg md:text-xl lg:text-2xl text-center my-6">
                {item.name}
              </p>
              <p className=" text-gray-500 md:w-2/3 mx-auto text-center my-6">
                {item.description}
              </p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Reviews;
/**
 *   <div className="flex justify-center">
        <div>
          <Slider ref={sliderRef} {...settings}>
            {data.map((item, index) => (
              <div
                key={index}
                className="bg-[#222] rounded-lg p-3 flex flex-col items-center gap-4"
              >
               
              </div>
            ))}
          </Slider>
        </div>
      </div>
 */
