import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "../components/utils/Title";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
const PortfolioDetails = ({ works }) => {
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const data = works.find((item) => item.id === parseInt(id));
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true, // Enable autoplay
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    verical: false,
    slidesToScroll: 2,
    initialSlide: 0,
    cssEase: "linear",
    verticalSwiping: false,
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const fetchData = (v) => {
    return request({
      url: `/work/${v}`,
    });
  };
  const { isLoading, data: details } = useQuery(["details", id], () =>
    fetchData(id)
  );
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container mx-auto px-6 md:px-16 my-12">
          <div className="flex justify-center my-6">
            <Title title={details?.data?.data?.work?.title} />
          </div>

          {details?.data?.data?.work?.images?.length && (
            <>
              <div className="text-center text-white mb-6">
                <p className="text-lg font-bold md:text-xl">{t("screens")}</p>
              </div>
              <Slider {...settings}>
                {details?.data?.data?.work.images.map((item, index) => (
                  <div className="md:px-3" key={index}>
                    <img
                      src={item}
                      alt={item.arTitle}
                      className="w-full h-[350px] object-cover"
                    />
                  </div>
                ))}
              </Slider>
            </>
          )}

          <div className="my-12 grid gird-cols-1 md:grid-cols-2 gap-6 overflow-x-hidden">
            <p
              className=" text-gray-300"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              {details?.data?.data?.work?.description}
            </p>
            <div className="flex flex-col items-center">
              {details?.data?.data?.work.services.length && (
                <>
                  <p
                    className="text-white font-bold text-md md:text-lg lg:text-xl mb-5"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {t("offers")}
                  </p>
                  <div className="grid grid-cols-2 gap-8">
                    {details?.data?.data?.work.services.map((item, index) => (
                      <div key={index} className="flex items-center gap-1 ">
                        <div className="text-main sans ">{++index} - </div>
                        <div>
                          <p className="text-white">{item}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PortfolioDetails;
