import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaSnapchatGhost,
  FaYoutube,
  FaBehanceSquare,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
const SocialMedia = ({ data }) => {
  return (
    <div>
      <ul className="flex items-center gap-3 flex-wrap">
        {data.facebook ? (
          <li>
            <a
              href={data.facebook}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <FaFacebookF size={20} />
            </a>
          </li>
        ) : null}
        {data.twitter ? (
          <li>
            <a
              href={data.twitter}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <BsTwitterX size={20} />
            </a>
          </li>
        ) : null}
        {data.instagram ? (
          <li>
            <a
              href={data.instagram}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <FaInstagram size={20} />
            </a>
          </li>
        ) : null}
        {data.youtube ? (
          <li>
            <a
              href={data.youtube}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <FaYoutube size={20} />
            </a>
          </li>
        ) : null}
        {data.behance ? (
          <li>
            <a
              href={data.behance}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <FaBehanceSquare size={20} />
            </a>
          </li>
        ) : null}
        {data.snapchat ? (
          <li>
            <a
              href={data.snapchat}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <FaSnapchatGhost size={20} />
            </a>
          </li>
        ) : null}
        {data.tiktok ? (
          <li>
            <a
              href={data.tiktok}
              target="_blank"
              rel="noreferrer"
              className="text-white duration-300 hover:text-main"
            >
              <FaTiktok size={20} />
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default SocialMedia;
/**
 *  {data.map((item, index) => (
         
        ))}
 */
