import React from "react";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
const About = () => {
  const fetchData = () => {
    return request({
      url: "/about",
    });
  };
  const { isLoading, data } = useQuery("about", fetchData);
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="main-div py-5 flex items-center my-12">
          {data?.data?.data?.about?.length ? (
            <div className="container mx-auto px-6 md:px-16 ">
              {data?.data?.data?.about.map((item, index) => (
                <div
                  key={index}
                  className={`flex items-center mb-3 md:mb-3 gap-4 md:gap-8 flex-col ${
                    index % 2 === 0 ? "  md:flex-row" : " md:flex-row-reverse"
                  }`}
                >
                  <div className="md:w-1/2">
                    <img
                      alt="about/img"
                      src={item.image}
                      className="w-full h-[300px] md:h-[450px] object-cover"
                      data-aos="zoom-in"
                      data-aos-delay="400"
                    />
                  </div>
                  <div className="md:w-1/2 flex flex-col items-center gap-4">
                    <p
                      className="text-xl md:text-2xl lg:text-3xl font-bold text-main"
                      data-aos="fade-down"
                      data-aos-delay="400"
                    >
                      {item.title}
                    </p>
                    <p
                      data-aos="fade-up"
                      data-aos-delay="500"
                      className="text-center text-slate-300"
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default About;
