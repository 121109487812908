import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import SocialMedia from "../utils/SocialMedia";

const Nav = ({ links, socialMedia, logo }) => {
  const { pathname } = useLocation();
  const isAnySocialMediaFilled = Object.values(socialMedia).some(
    (value) => value !== ""
  );
  const { i18n, t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const sidebarRef = useRef(null);
  const [showShadow, setShowShadow] = useState(false);

  // handle click outside
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShowSidebar();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // handle scroll
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
  };
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`w-screen h-20 flex items-center z-40 ${
          pathname === "/" ? "fixed top-0 left-0 right-0" : null
        }  overflow-hidden ${
          showShadow && pathname === "/" ? " bg-black" : "bg-transparent"
        } ${pathname !== "/" ? "bg-black" : null}`}
      >
        <div className="container mx-auto px-6 md:px-16">
          <div className="flex items-center justify-between">
            <ul className="hidden md:flex items-center gap-6 text-white">
              <>
                {links.map((link, i) => (
                  <li key={i}>
                    <NavLink to={link.path} className="text-white">
                      {i18n.language === "ar" ? link.arTitle : link.enTitle}
                    </NavLink>
                  </li>
                ))}
                {/* <li className=" cursor-pointer">
                  {i18n.language === "ar" ? (
                    <img
                      alt="lang-icon"
                      src={usa}
                      onClick={() => {
                        i18n.changeLanguage("en");
                        window.location.reload();
                      }}
                    />
                  ) : (
                    <img
                      alt="lang-icon"
                      src={ksa}
                      onClick={() => {
                        i18n.changeLanguage("ar");
                        window.location.reload();
                      }}
                    />
                  )}
                </li> */}
              </>
            </ul>
            <Link to="/">
              <img
                alt="logo"
                src={logo}
                className="w-32  md:w-40 object-cover"
              />
            </Link>
            <div className="md:hidden">
              <MdOutlineMenu
                size={30}
                className=" cursor-pointer text-white"
                onClick={() => setShowSidebar(true)}
              />
            </div>
          </div>
        </div>
      </div>
      {/*sidebar*/}
      <div
        ref={sidebarRef}
        className={`fixed top-0 duration-300  w-screen z-50 bg-gray-900 ${
          showSidebar ? "left-0" : "left-[-300%]"
        }`}
      >
        <div className="p-5">
          <IoIosClose
            size={30}
            className={`text-gray-400 cursor-pointer duration-1000 ${
              showSidebar ? " rotate-[360deg]" : null
            }`}
            onClick={() => setShowSidebar(false)}
          />
          <div className="flex flex-col items-center  mt-5">
            <ul className="mb-3">
              {links.map((link, i) => (
                <li
                  key={i}
                  className="mb-3"
                  onClick={() => setShowSidebar(false)}
                >
                  <Link
                    to={link.path}
                    className="text-white hover:text-gray-400"
                  >
                    {i18n.language === "ar" ? link.arTitle : link.enTitle}
                  </Link>
                </li>
              ))}
            </ul>
            {isAnySocialMediaFilled ? (
              <>
                <p className="text-white font-bold text-lg md:text-xl mb-4">
                  {t("social")}
                </p>
                <SocialMedia data={socialMedia} />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
