import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/utils/Title";
import Partners from "../components/home/Partners";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { request } from "../components/utils/axios";
import Spinner from "../components/utils/Spinner";
const Portfolio = ({ partners }) => {
  const { t } = useTranslation();
  const getCategories = () => {
    return request({
      url: "/categories",
    });
  };
  const { isLoading, data } = useQuery("categories", getCategories);
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(1);
  console.log("active id is ", activeId);
  const handleButtonClick = (item) => {
    setActiveId(item.id);
  };
  const handleNavigate = (index) => navigate(`/portfolio/${++index}`);
  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="overflow-hidden my-12">
          <div className="container mx-auto px-6 md:px-16 mb-4 overflow-hidden">
            <div className="flex justify-center flex-col items-center gap-3 overflow-hidden">
              <Title title={t("portfolio")} />
              {partners?.length ? (
                <h3
                  className="text-center font-bold text-xl md:text-2xl text-white"
                  data-aos="fade-left"
                  data-aos-delay="400"
                >
                  {t("happyy")}
                </h3>
              ) : null}
            </div>
          </div>
          {partners.length ? (
            <Partners data={partners} showTitle={false} />
          ) : null}

          <div className="container mx-auto px-6 md:px-16 overflow-hidden">
            <div
              data-aos="fade-up"
              data-aos-delay="400"
              className="flex items-center justify-center gap-4 md:gap-6 flex-wrap my-8 overflow-hidden "
            >
              {data?.data?.data?.categories.map((item, index) => (
                <button
                  onClick={() => handleButtonClick(item)}
                  className={`px-4 py-3 rounded-lg bg-white text-main flex items-center justify-center w-[150px] md:w-[250px] ${
                    activeId === item.id ? "bg-main text-white" : null
                  }`}
                  key={index}
                >
                  {item.name}
                </button>
              ))}
            </div>
            <div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              {data?.data?.data?.categories[activeId - 1]?.works?.length ? (
                <>
                  {data?.data?.data?.categories[activeId - 1].works.map(
                    (item, index) => (
                      <div
                        onClick={() => handleNavigate(index)}
                        key={index}
                        className=" overflow-hidden relative cursor-pointer"
                      >
                        <img
                          alt={item.title}
                          src={item.image}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 p-4">
                          <div className=" absolute right-4 bottom-6">
                            <p className="text-sm text-white mb-2">
                              {item.description}
                            </p>
                            <p className="text-white text-lg md:text-xl font-extrabold">
                              {item.title}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              ) : (
                <p className="text-white font-bold my-6 text-xl md:text-2xl lg:text-3xl">
                  لا يوجد اعمال الان
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;
/**
 * import React, { useState } from "react";

const Portfolio = ({ filter, , works }) => {
 
  
  const [ourWorks, setOurWorks] = useState(works);
  
 
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className=" overflow-x-hidden my-12">
       
        
          <div className="container mx-auto px-6 md:px-16 overflow-hidden">
           
            <div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              {data?.data?.data?.categories?.filter(
                (item) => item.id === activeId
              ).works.length ? (
                data?.data?.data?.categories
                  ?.filter((item) => item.id === activeId)
                  .map((work, index) => (
                    <div
                      onClick={() => handleNavigate(index)}
                      key={index}
                      className=" overflow-hidden relative cursor-pointer"
                    >
                      <img
                        alt={work.title}
                        src={work.image}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 p-4">
                        <div className=" absolute right-4 bottom-6">
                          <p className="text-sm text-white mb-2">
                            {work.description}
                          </p>
                          <p className="text-white text-lg md:text-xl font-extrabold">
                            {work.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className=" text-center font-bold text-x2 md:text-2xl text-white">
                  {t("noWork")}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;

 */
